<template>
  <div class="wrap upflie">
    <Loading v-show="$store.state.isLoading"/>
    <div v-show="!$store.state.isLoading">
      <van-form input-align="right" error-message-align="right">
        <van-field readonly label="姓名" :value="list.customername" />
        <van-field autosize readonly label="合同号" :value="list.contract_number" />
        <van-field readonly label="车架号" :value="list.vin" />
        <van-field readonly label="原银行名称" :value="list.bank" />
        <van-field readonly label="原卡号" :value="list.cardno" />
        <van-field readonly label="银行名称" :value="list.newbank" />
        <van-field readonly label="卡号" :value="list.newcardno" />
        <van-cell title="附件上传" is-link :to="{ path: '/upFileDetail', query: { contractNum: list.contract_number,wxid: list.wxid}}" value="已上传" />
        <van-field v-if="!list.signimg_url || list.signimg_url=='' || list.signimg_url==null" readonly label="划款授权书" value="--" />
        <van-cell v-if="list.signimg_url && list.signimg_url !=='' && list.signimg_url !==null" title="划款授权书" is-link @click='handdleClick()' value="已签名" />
        <van-field readonly label="申请时间" :value="list.apply_time" />
      </van-form>
      <div v-if="list.approval_status=='2'">
        <div offset="1" style="color:#1A1A1A;font-size:20px;margin:15px;">拒绝原因：</div>
        <div class="tips">
          <p>{{list.back_msg}} </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "@/components/Loading";
export default {
  components: {
    Loading
  },
  data() {
    return {
      list:this.$store.state.dataDetailList,
      imgtype:'changecardf',//附件展示标志
    };
  },
  mounted(){
    // let that = this;
    // sessionStorage.setItem('key', 'card3');
    // window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
    // 	if(sessionStorage.getItem('key') == 'card3') {
    //     that.$router.push({ path: '/application?flag=1' });
    //   }
    // }, false);
  },
  created(){
    
  },
  methods: {
    handdleClick(e){
      let listData = this.list;
      this.$store.commit('authorDetailUrl', listData.signimg_url);
      this.$router.push({ path: '/transferAuthorizationDetail' });
    }
  },
};
</script>
<style scoped>
.wrap{
  /* padding: 10px 0px;  */
  background-color: #fff; 
  height: 100%;
  }
.tips{
  color:#808080;
  font-size:13px;
  background-color: #F9F9F9;
  margin: 10px;
  min-height:50px;
  padding: 15px;
  /* margin-left: 12px; */
}
</style>
<style>
.wrap .van-field__control {
    color: #666666 !important;
}
.upflie .van-cell__value{
  color: #666666 !important;
}
.upflie .van-cell__title{
  color: #646566;
}
</style>